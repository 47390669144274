import {GraphqlQueryTable} from "../GraphqlQueryTable.jsx";
import {DeleteButton} from "../common/components/DeleteButton.jsx";
import {ToastContainer} from "react-toastify";


const VM_INSTANCE_LOG_LINK = `https://console.cloud.google.com/compute/instancesDetail/zones/us-central1-a/instances/{vmName}?project={vmProjectName}&tab=monitoring&pageState=(%22observabilityTab%22:(%22mainContent%22:%22logs%22))`

export const INSTANCES_QUERY = `query {
        data:powertabsInstances {
           name
           isRunning
         }
    }`

export const DELETE_MUTATION = `mutation($name: String!) {
        deletePowertabsInstance(name: $name) {
            name
            isRunning
        }
    }`


const buttons = ([
    {
        Header: "Delete Instance",
        Cell: (props) => (<div>
            <DeleteButton
                deleteMutation={DELETE_MUTATION}
                apiParameters={{name: props.row.original.name}}
                filePrefixName={props.row.original.name}
                initDisabled={!props.row.original.isRunning}
            />
        </div>)
    }
])


export const INSTANCES_COLUMNS = () => [
    ...buttons,
    {
        Header: 'Instance Name',
        Cell: (props) => (
            <a href={
                VM_INSTANCE_LOG_LINK
                    .replace("{vmName}", props.row.original.name)
                    .replace("{vmProjectName}", process.env.REACT_APP_POWERTABS_GCP)
            }> {props.row.original.name} </a>
        )

    },
]

export const PowertabsInstances = () => {
    return (
        <div>
            <ToastContainer autoClose={3000}/>
            <GraphqlQueryTable
                pageName="Powertabs Instances"
                generateColumns={INSTANCES_COLUMNS}
                query={INSTANCES_QUERY}
            />
        </div>
    )
}

