import axios from "axios";
import {makeApolloClientCall} from "../../../api/makeApolloApiCall.js";
import {SDNO_COMPANY} from "../data-subscription/queries.js";
import {useEffect, useState} from "react";

export const KeyAccountApprovalStatus = Object.freeze({
    PENDING_RETAILER_APPROVAL: "Pending Retailer Approval",
    APPROVED: "Approved",
    DENIED: "Denied",
    PENDING: "Pending"
})

export const getApprovalStatus = (status) => {
    if (!status) return KeyAccountApprovalStatus.PENDING

    return KeyAccountApprovalStatus[status]
}

export const getCategoryNameFromTag = (categoriesWithListSubCat, tag, productLevel) => {
    if (productLevel === "CATEGORY" || productLevel === "BRANDCAT") {
        return categoriesWithListSubCat.find(item => item.value === tag)?.displayName ?? tag
    } else if (productLevel === "DEPARTMENT") {
        // TODO: what to display if product level is department?
        return tag
    } else {
        return categoriesWithListSubCat.find(item => item.subcategories.includes(tag))?.displayName ?? tag
    }
}


export const flattenSubcatFromCategories = (categories) => {
    return categories.map((category) => {
        const subcategories = category.subcategories.map(subcategory => subcategory.value)
        return {...category, subcategories: subcategories}
    })
}

export const mapKeyAccountDetails = (subscriptions, companyList, categoriesWithListSubCat, geoFamilyIds, companiesWithContacts) => {
    const groupedSubscriptions = new Map()
    const filteredSubscriptions = subscriptions.filter(subscription => {
        const companyType = companiesWithContacts
            .find(company => company.geoFamilyId?.toString() === subscription.sec_mkt_key?.toString())?.type ?? "";
        return companyType === "Key Account Retailer"
    })

    filteredSubscriptions.forEach((subscription) => {
        const retailerName = geoFamilyIds.find(geoFamilyId => geoFamilyId.value?.toString() === subscription.sec_mkt_key?.toString())?.displayName
        const spinsOwner = companiesWithContacts
            .find(company => company.geoFamilyId?.toString() === subscription.sec_mkt_key?.toString())?.spinsContacts?.val
            ?.find(spinsContact => spinsContact.type === "SPINS Owner")
        const accountManager = companiesWithContacts
            .find(company => company.geoFamilyId?.toString() === subscription.sec_mkt_key?.toString())?.spinsContacts?.val
            ?.find(spinsContact => spinsContact.type === "Account Manager")
        const productCode = subscription.sec_prd_key.split('|')[1]
        const categoryName = getCategoryNameFromTag(categoriesWithListSubCat, productCode, subscription.prd_lvl)
        const companyName = companyList.find(company => company.value === subscription.salesforceid)?.displayName ?? subscription.salesforceid
        const groupId = `${subscription.sec_mkt_key}|${subscription.salesforceid}|${categoryName}`

        const subscriptions = groupedSubscriptions.get(groupId);
        const subscriptionRow = {
            ...subscription,
            createdAt: subscription.created_at,
            companyName: companyName,
            retailerName: retailerName ?? subscription.sec_mkt_key,
            spinsOwner: `${spinsOwner?.fname ?? ""} ${spinsOwner?.lname ?? ""}`,
            accountManager: `${accountManager?.fname ?? ""} ${accountManager?.lname ?? ""}`,
            approverEmailedSentAt: subscription.approver_emailed_sent_at,
        }
        subscriptions ? subscriptions.push(subscriptionRow) : groupedSubscriptions.set(groupId, [subscriptionRow]);
    })
    return groupedSubscriptions
}

export const flattenGroupedSubscriptions = (groupedSubscriptions) => {
    return Array.from(groupedSubscriptions.entries()).map(([key, subscriptions]) => {
        const categoryName = key.split("|")[2]
        return {
            id: key,
            createdAt: subscriptions[0].createdAt,
            companyName: subscriptions[0].companyName,
            retailerName: subscriptions[0].retailerName,
            spinsOwner: subscriptions[0].spinsOwner,
            accountManager: subscriptions[0].accountManager,
            categoryName: categoryName,
            status: subscriptions[0].status,
            approverEmailedSentAt: subscriptions[0].approverEmailedSentAt,
            subscriptionIds: subscriptions.map(subscription => subscription.id ?? subscription.data_subscriptions_id),
            note: subscriptions[0].note_text,
            noteId: subscriptions[0].note_id ?? undefined,
            disabledMailer: subscriptions[0].disable_mailer
        }
    })
}

export const useFetchCompaniesWithSpinsContacts = (subscriptions) => {
    const [loading, setIsLoading] = useState(true)
    const [companiesWithSpinsContacts, setCompaniesWithSpinsContacts] = useState([]);
    const fetchCompaniesWithSpinsContacts = async () => {
        const approvalRules = (await axios.get(`${process.env.REACT_APP_RIS_API_URL}/data-subscriptions/retailers/approval-rules`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${window.localStorage.getItem('access_token')}`
            }
        })).data
        const geoFamilyIds = [...new Set(subscriptions.map(subscription => subscription.sec_mkt_key.toString()))]
        const companyIds = approvalRules
            .filter(approvalRule => geoFamilyIds.includes(approvalRule?.geo_family_id?.toString()))
            .map(approvalRule => approvalRule.company_id)

        const sdnoCompanies = companyIds.reduce((acc, companyId) => {
            return acc + SDNO_COMPANY
                .replace("$sdnoCompanyId", `"${companyId}"`)
                .replace("$alias", `company_${companyId}`)
        }, "")
        const sdnoQuery = `query SdnoCompany { ${sdnoCompanies} }`

        return makeApolloClientCall(sdnoQuery, false).then(result => {
            const flattenedCompanies = Object.keys(result).flatMap(key => {
                return result[key]?.edges.map(edge => {
                    const node = edge.node
                    const approvalRule = approvalRules.find(approvalRule => approvalRule?.company_id === node.id)
                    return {...node, geoFamilyId: approvalRule?.geo_family_id}
                })
            })
            setCompaniesWithSpinsContacts(flattenedCompanies)
        }).catch(error => {
            console.log(`There was an error in fetching the spins owner.. ${error}`)
            setIsLoading(false)
        }).finally(() => setIsLoading(false))
    }

    useEffect(() => {
        if (subscriptions.length) {
            fetchCompaniesWithSpinsContacts().then();
        }
    }, [subscriptions])

    return {companiesWithSpinsContacts, loading}
}


export const updateSubscriptionStatus = async (subscription_ids, status) => {
    await axios.put(`${process.env.REACT_APP_RIS_API_URL}/data-subscriptions/data-subscriptions/update-subscription-status`, {
            subscription_ids: subscription_ids,
            status: status
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${window.localStorage.getItem('access_token')}`
            },
        }
    )
}


export const useFetchAutoApprovedRetailerIds = () => {
    const [loading, setIsLoading] = useState(true)
    const [autoApprovedRetailerIds, setAutoApprovedRetailerIds] = useState([]);

    const fetchAutoApprovedRetailerIds = async () => {
        axios.get(`${process.env.REACT_APP_RIS_API_URL}/data-subscriptions/retailers/auto-approved-ids`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${window.localStorage.getItem('access_token')}`
            }
        })
            .then(r => setAutoApprovedRetailerIds(r.data))
            .catch(error => console.log(`Error in fetching the auto approved retailer ids. ${error}`))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchAutoApprovedRetailerIds().then();
    }, [])

    return {autoApprovedRetailerIds, loading}
}