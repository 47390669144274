import Page from "../../Page.jsx";
import {DataGrid, GridToolbarQuickFilter} from "@mui/x-data-grid";
import React, {useEffect, useMemo, useState} from "react";

import {
    useFetchAllSubscriptions,
    useFetchCategories,
    useFetchGeoFamilyIds,
    useFetchMdmCompanies
} from "../data-subscription/DataSubscription.hooks.jsx";
import {Box, Button} from "@mui/material";
import {
    flattenGroupedSubscriptions,
    flattenSubcatFromCategories,
    getApprovalStatus,
    mapKeyAccountDetails,
    useFetchAutoApprovedRetailerIds,
    useFetchCompaniesWithSpinsContacts
} from "./KeyAccountsApproval.hooks.jsx";
import {SubscriptionStatusModal} from "./SubscriptionStatusModal.jsx";
import {EditTextarea} from "./EditTextArea.jsx";
import {upsertNote} from "./KeyAccountsApproval.handlers.js";
import {Edit} from "@mui/icons-material";
import {DoNotSendCheckbox} from "./DoNotSendCheckBox.jsx";


const COLUMNS = [
    {
        field: "createdAt",
        headerName: "Production Date",
        flex: 1,
        renderCell: (params) => {
            return (new Date(params?.formattedValue)).toLocaleDateString("en-CA")
        },
        valueGetter: (params) => {
            return params.value ? new Date(params.value) : undefined
        }
    },
    {
        field: "companyName",
        headerName: "Co/Brand",
        flex: 1
    },
    {
        field: "retailerName",
        headerName: "Retailer",
        flex: 1
    },
    {
        field: "spinsOwner",
        headerName: "SPINS Owner",
        flex: 1
    },
    {
        field: "accountManager",
        headerName: "Account Manager",
        flex: 1
    },
    {
        field: "categoryName",
        headerName: "Category",
        flex: 1
    },
    {
        field: "status",
        headerName: "KA Approval Status",
        flex: 1,
        renderCell: params => getApprovalStatus(params?.formattedValue)
    },
    {
        field: "note",
        headerName: "Notes",
        flex: 2,
        editable: true,
        renderEditCell: (params) => <EditTextarea {...params} />,
        renderCell: (params) => {
            return <div style={{display: 'inline-flex', width: '100%', justifyContent: "space-between"}}>
                <span>{params.formattedValue}</span>
                <Button onClick={() => {
                    params.api.startCellEditMode({id: params.id, field: params.field});
                }}><Edit/></Button>
            </div>
        }
    },
    {
        field: "approverEmailedSentAt",
        headerName: "Date Sent",
        flex: 1,
        renderCell: (params) => {
            return params?.formattedValue ? (new Date(params.formattedValue)).toLocaleDateString("en-CA") : ""
        },
        valueGetter: (params) => {
            return params.value ? new Date(params.value) : undefined
        }
    },
    {
        field: "disabledMailer",
        headerName: "Do Not Send Mail",
        flex: 1,
        type: 'boolean',
        editable: true,
        renderCell: (params) => <DoNotSendCheckbox params={params}/>

    }
]

export const KeyAccountsApproval = () => {
    const {subscriptions, loading: subscriptionsLoading, fetchAllSubscriptions} = useFetchAllSubscriptions()
    const {companyList, isCompaniesLoading: companiesLoading} = useFetchMdmCompanies()
    const {categories, isCategoriesLoading: categoriesLoading} = useFetchCategories()
    const {geoFamilyIds, isGeoFamilyIdsLoading: geoFamilyIdsLoading} = useFetchGeoFamilyIds()
    const {autoApprovedRetailerIds, loading: autoApprovedRetailerIdsLoading} = useFetchAutoApprovedRetailerIds()
    const {
        companiesWithSpinsContacts,
        loading: companiesWithSpinsContactsLoading
    } = useFetchCompaniesWithSpinsContacts(subscriptions)

    const [categoriesWithListSubCat, setCategoriesWithListSubCat] = useState([])
    const [selectedSubscriptions, setSelectedSubscriptions] = useState([])
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)

    useEffect(() => {
        if (categories.length) {
            const newCategories = flattenSubcatFromCategories(categories)
            setCategoriesWithListSubCat(newCategories)
        }
    }, [categories])

    const rows = useMemo(() => {
        if (subscriptions.length && companyList.length && categories.length && companiesWithSpinsContacts.length) {
            const filteredSubscriptions = autoApprovedRetailerIds.length ?
                subscriptions.filter(subscription => !autoApprovedRetailerIds.includes(parseInt(subscription.sec_mkt_key))) : subscriptions
            const groupedSubscriptions = mapKeyAccountDetails(
                filteredSubscriptions, companyList, categoriesWithListSubCat, geoFamilyIds, companiesWithSpinsContacts
            )
            return flattenGroupedSubscriptions(groupedSubscriptions);
        }
        return []
    }, [subscriptions, companyList, categories, companiesWithSpinsContacts, autoApprovedRetailerIds, categoriesWithListSubCat, geoFamilyIds])

    const handleClose = () => {
        fetchAllSubscriptions().finally(() => {
            setIsStatusModalOpen(false)
        })
    }

    function QuickSearchToolbar() {
        return (
            <Box sx={{p: 1, pb: 0,}}
                 style={{display: "flex", justifyContent: "end", paddingBottom: "10px", borderBottom: "1px solid gray"}}
            >
                <GridToolbarQuickFilter
                    debounceMs={500}
                    size={"medium"}
                    data-testid={"datagrid-quick-filter"}
                    quickFilterParser={(searchInput) => {
                        return [searchInput];
                    }}
                    variant="outlined"
                    sx={{paddingBottom: 0}}
                />
            </Box>
        );
    }

    return (
        <Page name={"Key Accounts Approval"}>
            <DataGrid
                checkboxSelection
                columns={COLUMNS}
                rows={rows}
                slots={{toolbar: QuickSearchToolbar}}
                getRowId={(row) => row.id}
                loading={companiesLoading || subscriptionsLoading || categoriesLoading || geoFamilyIdsLoading || autoApprovedRetailerIdsLoading || companiesWithSpinsContactsLoading}
                autoHeight={true}
                disableVirtualization={!!process.env.REACT_APP_DISABLE_VIRTUALIZATION}
                data-testid={'key-accounts-list-data-grid'}
                columnBuffer={7}
                onRowSelectionModelChange={(ids) => {
                    const selectedRows = rows.filter(row => ids.includes(row.id))
                    setSelectedSubscriptions(selectedRows);
                }}
                processRowUpdate={async (newValue, {note: oldNote}) => {
                    const {note, subscriptionIds, noteId} = newValue
                    if (oldNote !== note) {
                        await upsertNote(note, subscriptionIds, noteId)
                    }
                    return newValue
                }}
            />

            <Button
                disabled={selectedSubscriptions.length < 1}
                style={{display: "flex", marginTop: "2px"}}
                variant="contained"
                onClick={() => setIsStatusModalOpen(true)}
            >
                Edit Status
            </Button>

            <SubscriptionStatusModal
                isOpen={isStatusModalOpen}
                handleClose={handleClose}
                subscriptions={selectedSubscriptions}
            />

        </Page>
    )
}