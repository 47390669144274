import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useFetchMdmCompanies} from "../DataSubscription.hooks.jsx";
import {CircularProgress} from "@mui/material";
import {DataSubscription} from "../DataSubscription.jsx";

export const EditDataSubscription = () => {
  const {salesforceid} = useParams()
  const searchParams = new URLSearchParams(window.location.search);
  const application = searchParams.get("application")
  const {companyList, isCompaniesLoading: companiesLoading} = useFetchMdmCompanies();

  const [data, setData] = useState({})

  useEffect(() => {
    if (salesforceid && companyList) {
      const selectedCompany = companyList.find(company => company.value === salesforceid)
      if (selectedCompany) {
        setData({selectedCompany, application})
      }
    }
  }, [salesforceid, application, companyList])

  if (companiesLoading || !data) {
    return (<CircularProgress className={"spinning-loader"} data-testid={"main-loader"}/>)
  }

  return (
    <DataSubscription initialState={data}/>
  )
}
