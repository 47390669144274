import {Checkbox} from "@mui/material";
import {updateSubscriptionDisableMailer} from "./KeyAccountsApproval.handlers.js";
import React from "react";

export function DoNotSendCheckbox({params}) {
    return <Checkbox checked={params.value}
                     onChange={async (e) => {
                         const disableMailer = e.target.checked;
                         params.api.updateRows([{
                             id: params.id,
                             disabledMailer: disableMailer
                         }])
                         await updateSubscriptionDisableMailer(params.row.subscriptionIds, disableMailer).then(({data}) => {
                             if (data[0].disable_mailer !== disableMailer)
                                 params.api.updateRows([{
                                     id: params.id,
                                     disabledMailer: !disableMailer
                                 }])
                         })
                     }}/>;
}