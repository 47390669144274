import {DataGrid, GridToolbarQuickFilter} from "@mui/x-data-grid";
import React, {useEffect, useMemo, useState} from "react";
import Page from "../../../Page.jsx";
import {useFetchDepartments, useFetchMdmCompanies, useFetchSubscriptionsByCompany} from "../DataSubscription.hooks.jsx";
import {Box} from "@mui/material";

const COLUMNS = [
    {
        field: "application",
        headerName: "Application Name",
        flex: 1
    },
    {
        field: "companyName",
        headerName: "Company Name",
        flex: 1
    },
    {
        field: "sec_mkt_key",
        headerName: "Geo Family ID",
        flex: 1
    },
    {
        field: "prd_lvl",
        headerName: "Product Level",
        flex: 1
    },
    {
        field: "productDescription",
        headerName: "Product Description",
        flex: 3
    },
]


const mapCompanyName = (subscriptions, companyList, flatDepartments) => {
    return subscriptions.map((subscription) => {
        const productCodes = subscription.sec_prd_key.map(item => item.sec_prd_key.split('|')[1])
        return ({
            ...subscription,
            companyName: companyList.find(company => company.value === subscription.salesforceid)?.displayName ?? subscription.salesforceid,
            productDescription: [...new Set(productCodes.map(productCode => flatDepartments.find(item => item.value === productCode)?.displayName ?? productCode))]
        })
    })
}

export function SubscriptionsList() {
    const {companyList, isCompaniesLoading: companiesLoading} = useFetchMdmCompanies()
    const {subscriptions, loading: subscriptionsLoading} = useFetchSubscriptionsByCompany()
    const {departments, isDepartmentsLoading: departmentsLoading} = useFetchDepartments()
    const [flatDepartments, setFlatDepartments] = useState([])

    useEffect(() => {
        if (departments.length) {
            const flattenedDepartments = departments.flatMap((dept) => {
                return [
                    {displayName: dept.displayName, value: dept.value},
                    ...dept.categories.flatMap((category) => {
                        return [
                            {displayName: category.displayName, value: category.value},
                            ...category.subcategories.map((subcat) => ({
                                displayName: subcat.displayName,
                                value: subcat.value
                            }))
                        ]
                    })
                ]
            })
            setFlatDepartments(flattenedDepartments)
        }
    }, [departments])
    
    const handleRowClick = (params) => {
        const salesforceId = params.row.salesforceid
        const application = params.row.application
        window.open(`/data-subscription/edit/${salesforceId}?application=${application}`, "_blank")
    }

    const rows = useMemo(() => {
        if (subscriptions.length && companyList.length && flatDepartments.length) {
            return mapCompanyName(subscriptions, companyList, flatDepartments)
        }
        return []
    }, [subscriptions, companyList, flatDepartments])

    function QuickSearchToolbar() {
        return (
            <Box sx={{p: 1, pb: 0,}}
                 style={{display: "flex", justifyContent: "end", paddingBottom: "10px", borderBottom: "1px solid gray"}}
            >
                <GridToolbarQuickFilter
                    debounceMs={500}
                    size={"medium"}
                    data-testid={"datagrid-quick-filter"}
                    quickFilterParser={(searchInput) => {
                        return [searchInput];
                    }}
                    variant="outlined"
                    sx={{paddingBottom: 0}}
                />
            </Box>
        );
    }

    return <Page name={"Subscriptions List"}>
        <DataGrid
            columns={COLUMNS}
            rows={rows}
            slots={{toolbar: QuickSearchToolbar}}
            getRowId={(row) => `${row.application}-${row.salesforceid}-${row.prd_lvl}`}
            loading={companiesLoading || subscriptionsLoading || departmentsLoading}
            autoHeight={true}
            disableVirtualization={!!process.env.REACT_APP_DISABLE_VIRTUALIZATION}
            data-testid={'subscriptions-list-data-grid'}
            onRowClick={handleRowClick}
        />
    </Page>
}