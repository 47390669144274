import Dialog from "@mui/material/Dialog";
import {Button, IconButton} from "@mui/material";
import {useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {toast, ToastContainer} from "react-toastify";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {ComboBox} from "../../comboBox/ComboBox.jsx";
import {KeyAccountApprovalStatus, updateSubscriptionStatus} from "./KeyAccountsApproval.hooks.jsx";


export const SubscriptionStatusModal = ({isOpen, handleClose, subscriptions}) => {
    const [newStatus, setNewStatus] = useState(null)

    function handleUpdateSubscription() {
        const subscriptionIds = subscriptions.flatMap(subscription => subscription.subscriptionIds);
        updateSubscriptionStatus(subscriptionIds, newStatus)
            .then(() => {
                toast.success("Update success!")
            })
            .catch(err => toast.error(err))
            .finally(() => handleClose());
    }

    const APPROVAL_STATUS = Object.keys(KeyAccountApprovalStatus).map(key => {
        return {
            "displayName": KeyAccountApprovalStatus[key],
            "value": key
        }
    })

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                fullWidth={true}
            >
                <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
                    Update Subscription Status
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent style={{"paddingTop": 0}}>
                    <h2>This action will update {subscriptions.reduce((acc, item) => acc + item?.subscriptionIds?.length, 0)} subscription(s).</h2>
                    <ComboBox
                        testId="subscription-combobox"
                        style={{"paddingTop": 1}}
                        label="New Status"
                        data={APPROVAL_STATUS}
                        isMultiple={false}
                        disableCloseOnSelect={false}
                        onChange={(event, value) => {
                            setNewStatus(value.value)
                        }
                        }
                    />
                </DialogContent>
                <DialogActions style={{"paddingRight": "20px"}}>
                    <Button
                        variant="contained"
                        onClick={handleUpdateSubscription}
                    > Confirm </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleClose()
                        }}> Cancel </Button>
                </DialogActions>
            </Dialog>
            <ToastContainer/>
        </div>
    )
}


