import axios from "axios";

export async function upsertNote(note_text,subscription_ids, note_id) {
    return await axios.post(`${process.env.REACT_APP_RIS_API_URL}/data-subscriptions/data-subscriptions/upsert-notes`, {
            subscription_ids, note_text, note_id
        }, {
            headers:
                {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${window.localStorage.getItem('access_token')}`
                }
        }
    )
}
export async function updateSubscriptionDisableMailer(subscription_ids, disable_mailer) {
    return await axios.post(`${process.env.REACT_APP_RIS_API_URL}/data-subscriptions/data-subscriptions/update-batch-disabled-mailer`, {
            subscription_ids, disable_mailer
        }, {
            headers:
                {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${window.localStorage.getItem('access_token')}`
                }
        }
    )
}