import {toast, ToastContainer} from "react-toastify";
import {Box} from "@mui/material";
import Page from "../../Page.jsx";
import * as React from "react";
import {useEffect, useState} from "react";
import axios from "axios";
import {DataGrid, GridToolbarQuickFilter} from "@mui/x-data-grid";
import {INSTANCES_QUERY} from "./PowertabsInstances.jsx";
import {makeApolloClientCall} from "../../../api/makeApolloApiCall.js";


export const COLUMNS = [
    {
        field: "reportName",
        headerName: "Report Name",
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        filterable: true,
    },
    {
        field: "status",
        headerName: "Is Success?",
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        filterable: true,
        type: 'boolean',
        valueGetter: (param) => param.row.entries[0].status === 'success'
    },
    {
        field: "vmStatus",
        headerName: "Is VM Running?",
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        filterable: true,
        type: "boolean"
    },
    {
        field: "lastRun",
        headerName: "Last Run",
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        filterable: true,
        type: "date",
        valueGetter: (param) => new Date(param.row.entries[0].date),
        renderCell: (param) => (
            <div>
                {new Date(param.row.entries[0].date).toISOString()}
            </div>
        ),
    }
]

export const useFetchPowertabsInstances = () => {
    const [powertabsInstances, setPowertabsInstances] = useState([])
    const [isPowertabsInstancesLoading, setIsPowertabsInstancesLoading] = useState(true);

    const fetchPowertabsInstances = async () => {
        setIsPowertabsInstancesLoading(true)
        await makeApolloClientCall(INSTANCES_QUERY, false)
            .then(result => {
                setPowertabsInstances(result.data)
            })
    }
    useEffect(() => {
        fetchPowertabsInstances()
            .catch(error => {
                setPowertabsInstances([])
                toast.error(error.message)
            })
            .finally(() => setIsPowertabsInstancesLoading(false))
    }, [])

    return {powertabsInstances, isPowertabsInstancesLoading}
}


export const useFetchSubscriptionStatusLogs = () => {
    const [statusLogs, setStatusLogs] = useState([])
    const [isStatusLogsLoading, setIsStatusLogsLoading] = useState(true);

    const fetchSubscriptionStatusLogs = async () => {
        setIsStatusLogsLoading(true)
        await axios.get(`${process.env.REACT_APP_RIS_API_URL}/poshta/status/logs?file_prefix=SPINS+PowerTabs`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${window.localStorage.getItem('access_token')}`
            },
        })
            .then(({data}) => {
                const report_names = Array.from(new Set(data.map(row => row.report_name)))
                report_names.sort()
                const logsByReportName = report_names.reduce((acc, report) => {
                    const entries = data.filter(row => row.report_name === report)
                        .map(row => ({
                            status: row.status,
                            date: row.date,
                        }))
                    entries.sort((a, b) => b.date?.localeCompare(a.date))
                    return [
                        ...acc,
                        {
                            reportName: report,
                            entries: entries,
                        }
                    ]

                }, [])
                setStatusLogs(logsByReportName)
            })
    }

    useEffect(() => {
        fetchSubscriptionStatusLogs()
            .catch(() => setStatusLogs([]))
            .finally(() => setIsStatusLogsLoading(false))
    }, [])

    return {statusLogs, isStatusLogsLoading}
}

export const PowertabsStatus = () => {
    const {statusLogs, isStatusLogsLoading} = useFetchSubscriptionStatusLogs()
    const {powertabsInstances, isPowertabsInstancesLoading} = useFetchPowertabsInstances()

    const runningPowertabsInstances = powertabsInstances.filter(value => value.isRunning).map(value => value.name)
    const rows = statusLogs.map((row) => {
        const vmPrefix = `csv-${row.reportName.replaceAll(' ', '-').slice(0, 24)}`.toLowerCase()
        const vmRegex = new RegExp(`${vmPrefix}-\\d{8}-\\d{2}-\\d{2}-\\d{2}-.*`)
        return {vmStatus: runningPowertabsInstances.some(value => vmRegex.test(value)), ...row}
    })


    return (
        <div>
            <ToastContainer autoClose={3000}/>
            <div data-testid={"status-logs-test-id"}>
                <Page name={"Subscriptions Status Logs"}>
                    <DataGrid
                        columns={COLUMNS}
                        rows={rows}
                        slots={{
                            toolbar: () => <Box sx={{p: 1, pb: 0,}}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    paddingBottom: "10px",
                                                    borderBottom: "1px solid gray"
                                                }}
                            >
                                <GridToolbarQuickFilter
                                    debounceMs={500}
                                    size={"medium"}
                                    data-testid={"datagrid-quick-filter"}
                                    quickFilterParser={(searchInput) => {
                                        return [searchInput];
                                    }}
                                    variant="outlined"
                                    sx={{paddingBottom: 0}}
                                />
                            </Box>
                        }}
                        getRowId={(row) => `${row.reportName}`}
                        loading={isStatusLogsLoading}
                        autoHeight={true}
                        disableVirtualization={!!process.env.REACT_APP_DISABLE_VIRTUALIZATION}
                        data-testid={'subscriptions-list-data-grid'}
                    />
                </Page>
            </div>
        </div>
    )
}

